<template>
  <v-card>
    <v-card-title>
      VP画像
    </v-card-title>
    <v-img
      :src="`${url}${imageSrc}`"
      width="90%"
    >
    </v-img>
  </v-card>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import enterShopStoreModule from './enterShopStoreModule'

const USER_APP_STORE_MODULE_NAME = 'app-enterShop'

export default {
  components: {},
  created() {
    const cameraId = sessionStorage.getItem('cameraId')

    sessionStorage.removeItem('cameraId')

    store
      .dispatch('app-enterShop/fetchImageData', {
        params: {
          cameraId,
        },
      })
      .then(response => {
        this.imageSrc = response.data.result.data.imageUrl
      })
      .catch(error => {
        // ログイン画面に遷移する
        console.log(error)
      })
  },
  methods: {},
  setup() {
    const imageSrc = ref()

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, enterShopStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const currentUrl = window.location.href
    const url = currentUrl.substr(0, currentUrl.indexOf('#'))

    return {
      imageSrc,
      url,
    }
  },
}
</script>
